<template>
  <section>
    <v-container>
      <iframe style="height: 500px !important; width: 100%; border: none;" class="rounded-lg" src="https://www.youtube.com/embed/PCcTAS53Inc?autoplay=1&mute=1"></iframe>
      <v-card class="mt-2" outlined>
        <v-card-title>
          <span class="text-h4">
            Welcome to Scuba Diving!
          </span>
        </v-card-title>
        <v-card-text class="body-1">
          <p>Are you ready to embark on an incredible adventure that will take you beneath the surface of the ocean? Scuba diving is an amazing activity that allows you to explore the underwater world, encounter marine life up close, and experience a sense of weightlessness that is unlike anything you've ever felt before.</p>
          <p>At Sea2Sea Scuba, we are excited to help you get started on your scuba diving journey. As a certified PADI dive center located in Phoenix, Arizona, we offer a variety of scuba diving courses that will teach you everything you need to know to become a confident and capable scuba diver.</p>

          <v-btn @click="doSearchOpenWater" color="primary" class="px-10">Start with Open Water</v-btn>

          <br>
          <div class="text-h5 font-weight-bold my-4">
            The Basics
          </div>

          <p>Scuba diving is a type of underwater diving where the diver uses a self-contained underwater breathing apparatus (SCUBA) to breathe. This equipment allows you to breathe underwater and stay submerged for extended periods of time.</p>

          <p>To become a certified scuba diver, you'll need to complete the PADI Open Water Diver Course. This course will teach you the basic skills and knowledge you need to dive safely. The course consists of three main parts:
          Knowledge Development</p>

          <p>You'll start by learning the basic principles of scuba diving, including dive equipment, diving techniques, and diving safety. You'll complete this part of the course through self-study using the PADI Open Water Diver Manual or eLearning.</p>

          <div class="text-h5 font-weight-bold my-4">
            Confined Water Dives
          </div>

          <p>Next, you'll learn and practice scuba diving skills in a confined water environment, such as a swimming pool. You'll learn important skills such as how to breathe underwater, how to control your buoyancy, and how to communicate with your dive buddy.</p>

          <div class="text-h5 font-weight-bold my-4">
            Open Water Dives
          </div>

          <p>Finally, you'll complete four open water dives in a natural body of water, such as a lake or ocean. Here, you'll apply the skills you learned in the confined water dives and experience the thrill of diving in the open water.</p>

          <div class="text-h5 font-weight-bold my-4">
              Getting Certified by PADI
          </div>

          <p>PADI (Professional Association of Diving Instructors) is the world's largest scuba diving training organization. When you become certified by PADI, you'll have a certification that is recognized and respected worldwide.</p>

          <p>At Sea2Sea Scuba, we offer the PADI Open Water Diver Course, taught by experienced instructors who will guide you through the process, ensuring that you have a safe and enjoyable experience while learning to scuba dive.</p>

          <p>The PADI Open Water Diver Course is just the beginning of your scuba diving journey. Once you become certified, you'll have the skills and knowledge you need to explore the underwater world and continue your scuba diving education with more advanced courses.</p>

          <p>Contact Sea2Sea Scuba today to learn more!</p>
        </v-card-text>
      </v-card>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'welcomeToDiving',
  methods: {
    doSearchOpenWater () {
      this.$router.push({ name: 'courses', params: { search: 'Open Water' } })
    }
  },
  created () {
    this.doSetDocumentTitle('Welcome to Scuba Diving!', true)
  }
}
</script>

<style scoped>

</style>
