import { render, staticRenderFns } from "./welcomeToDiving.vue?vue&type=template&id=58aca474&scoped=true&"
import script from "./welcomeToDiving.vue?vue&type=script&lang=js&"
export * from "./welcomeToDiving.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58aca474",
  null
  
)

export default component.exports